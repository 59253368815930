<div class="error-page d-flex align-items-center ">
    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'noCustomerFound'">
        <em class="far fa-exclamation-triangle error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>Invalid Account Link</h1>
            <p>The customer that you are trying to access no longer exists. Please contact a SiteOwl administrator for more information.</p>
        </div>
    </div>

    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'noAccessCustomer'">
        <em class="far fa-lock-alt error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>No Access to Account</h1>
            <p>Please request access from your SiteOwl administrator to enable usage of this link and access the customer.
            </p>
        </div>
    </div>


    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'inValidProjectLink'">
        <em class="far fa-exclamation-triangle error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>Invalid Project Link</h1>
            <p>The project that you are trying to access no longer exists. Please contact a SiteOwl administrator for more information.
            </p>
        </div>
    </div>

    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'noAccessToProject'">
        <em class="far fa-lock-alt error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>No Access to Project</h1>
            <p>Please request access from your SiteOwl administrator to enable usage of this link and access the project.</p>
        </div>
    </div>



    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'inValidTicketLink'">
        <em class="far fa-exclamation-triangle error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>Invalid Ticket Link</h1>
            <p>The Ticket that you are trying to access no longer exists. Please contact a SiteOwl administrator for more information.
            </p>
        </div>
    </div>

    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'noAccessToTicket'">
        <em class="far fa-lock-alt error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>No Access to Ticket</h1>
            <p>Please request access from your SiteOwl Administrator to enable usage of this link and access the Ticket.</p>
        </div>
    </div>


    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'noAccessToDevice'">
        <em class="far  fa-lock-alt error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>No Access to Device</h1>
            <p>Please request access from your SiteOwl Administrator to enable usage of this link and access the Device.</p>
        </div>
    </div>

    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'inValidDeviceLink'">
        <em class="far fa-exclamation-triangle error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>Invalid Device Link</h1>
            <p>The Device that you are trying to access no longer exists. Please contact a SiteOwl Administrator for more information.
            </p>
        </div>
    </div>

    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'inValidFloorPlan'">
        <em class="far fa-exclamation-triangle error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>Invalid Floor Plan</h1>
            <p>The Floor Plan that you are trying to access no longer exists. Please contact a SiteOwl Administrator for more information.
            </p>
        </div>
    </div>

    <div class="col-sm-8 offset-sm-2 d-flex align-items-center" *ngIf="selection === 'inValidProjectLink'">
        <em class="far fa-exclamation-triangle error-icon"></em>
        <div class="error-content-wrapper clearfix">
            <h1>Invalid Project Link</h1>
            <p>The Project that you are trying to access no longer exists. Please contact a SiteOwl administrator for more information.
            </p>
        </div>
    </div>
</div>