/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, Environment, EventEmitterType, EventService, Menu, MixpanelObjectService, MixpanelService, Utilities } from '@SiteOwl/core';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'so-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {

  @Input() errorData: any;
  fromBroadcast: any = {};
  menu: Menu = {
    hideLeftMenu: false,
    title: 'Create New Site',
    subMenu: {
      hideBackButton: true,
      hideHeader: false,
      hideActionButton: true,
      title: null
    }
  };
  constructor(private route: ActivatedRoute,
    private eventService: EventService,
    private authService: AuthService,
    private mixpanelService: MixpanelObjectService,
    private mixpanel: MixpanelService,
    private router: Router,
    private location: Location,
    private sanitizer: DomSanitizer,
    private readonly env: Environment
  ) {

  }

  ngOnInit() {
    if (Utilities.isEmpty(this.route.snapshot.data['error'])) {
      this.fromBroadcast = { padding: '130px 0 0 65px' };
    } else {
      if (this.route.snapshot.url[0] && this.route.snapshot.url[0].path === 'error' && this.route.snapshot.url[1].path === '401') {
        this.errorData = {
          errorCode: 401,
          message: 'Unauthorized Request',
          description: 'Sorry, this content does not exist or you do not have access to it.'
        };
        this.logout();
      } else if (this.route.snapshot.url[0] && this.route.snapshot.url[0].path === 'error' && this.route.snapshot.url[1].path === '403') {
        this.errorData = {
          errorCode: 403,
          message: 'Access Denied',
          description: "Sorry, you do not seem to have access to this page."
        };
        this.eventService.broadcast(EventEmitterType.RouteChanged, { hideLeftMenu: true });
      } else if (this.route.snapshot.url[0] && this.route.snapshot.url[0].path === 'error' && (this.route.snapshot.url[1].path === '500' || this.route.snapshot.url[1].path === '0')) {
        this.errorData = {
          errorCode: 500,
          message: 'Internal Server Error',
          description: ""
        };
      } else if (this.route.snapshot.url[0] && this.route.snapshot.url[0].path === 'error' && this.route.snapshot.url[1].path === '422') {
       
      } else {
        this.errorData = {
          errorCode: 404,
          message: 'Page Not Found',
          description: this.sanitizer.bypassSecurityTrustHtml('Sorry, this page does not seem to exist. <br> Please contact your SiteOwl administrator if you need assistance.'),
        };
      }
    }

  }
  backToPage() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  logout() {
    const trackMixPanel: any = {}
    this.mixpanelService.trackEvent('Logged-Out', trackMixPanel, 'User Logged Out ');
    this.mixpanel.resetMixPanel();
    this.authService.clearUserData();
    if (this.env.fronteggLogin) {
      window.location.href = `${this.env.fronteggBaseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}/public/login`;
    } else {
      this.router.navigate(['public/login']);
    }
  }

}
