import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { errorPagesRoutes } from './lib.routes';
import { ErrorPageComponent } from './component/error-page/error-page.component';
import { InvalidPageComponent } from './component/invalid-page/invalid-page.component';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(errorPagesRoutes)],
  declarations: [ErrorPageComponent, InvalidPageComponent],
  exports: [ErrorPageComponent, InvalidPageComponent],
})
export class ErrorPagesModule {}
